import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const KreditSelbststaendige = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader
                h1Title="Du bist Selbstständig? Das wichtigste rund um den Kredit für Selbständige"
                showCalc={false}
            />
            <Article>
                <h2>Selbstständig und auf Kreditsuche? So schwer ist es wirklich.</h2>
                <p>
                    Egal, ob dein Büro ein eingebauter Schreibtisch in der Schlafzimmerecke, ein Co-Working-Space in der
                    Innenstadt oder eine eigene Praxis ist – der Weg zum{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>{" "}
                    ist für dich meist härter. Rechne damit, dass du zusätzliche Unterlagen vorlegen musst, um dein
                    Einkommen und deinen Schuldenstand nachzuweisen. Erhöhe deine Chancen auf einen Kredit, indem du
                    deine geschäftlichen und privaten Finanzen voneinander trennst und Steuerabzüge reduzierst. In
                    diesem Artikel erfährst du als Selbstständige:r, wie du den Kredit bekommst, den du verdienst.
                </p>
                <hr />

                <h2>Welche Arten von Kredit gibt es für Selbstständige?</h2>
                <ul>
                    <li>
                        Investitionskredit: Finanzierung nötiger Anlagevermögen wie Maschinen, Firmenfahrzeugen,
                        Computer oder Büromöbel
                    </li>
                    <li>
                        Betriebsmittel- oder Kontokorrentkredit: Können dazu dienen, Zeit zu überbrücken, in der deine
                        Kunden ihre Rechnungen noch nicht gezahlt haben. Mit Hilfe des Kredits können laufende Ausgaben
                        gedeckt werden. Meistens hat man einen bestimmten Bezugsrahmen für das Konto, der dann als
                        Kontokorrentkredit bezeichnet wird.
                    </li>
                    <li>
                        Förderkredit: Ein zinsgünstiger Kredit, mit dem die Wirtschaft gefördert werden soll. Hier gibt
                        es je nach Bundesland unterschiedliche Abteilungen zur Wirtschaftsförderungen, eine andere
                        Anlaufstelle ist die Austria Wirtschaftsservice GmbH (AWS)
                    </li>
                </ul>
                <p>
                    Bevor du einen Kredit für dein Unternehmen beantragst, ist es sehr wichtig, dich darüber zu
                    informieren, welche verschiedenen Möglichkeiten es gibt und welche davon in deinem Fall wirklich
                    sinnvoll ist. Scheue nicht davor zurück, dich zu diesem Thema beraten zu lassen. Vielleicht kannst
                    du dich auch mit anderen Selbstständigen austauschen, die schon einmal einen Kredit beantragt haben.
                    Ansonsten ist es am besten, mit Finanzexperten zu sprechen, die deine individuelle Situation
                    einschätzen können.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Beratung anfordern!
                    </a>
                </p>
                <hr />

                <h2>Was du beachten solltest, wenn du einen Immobilienkredit beantragen möchtest:</h2>
                <p>
                    Für Selbstständige ist der Kreditprozess eigentlich derselbe wie für andere. Die Anforderungen sind
                    grundsätzlich gleich, nur die Anforderungen an die Dokumente sind etwas andere. Während angestellte
                    Antragsteller:innen bloß ihren Einkommensnachweis vorlegen müssen, sind es bei selbständigen
                    Kreditnehmer:innen die Steuererklärungen einschließlich aller Tabellen. Anfangs wirkt es jedoch, als
                    wäre man deutlich im Nachteil. Durch die Steuerabzüge ist dein Nettoeinkommen niedriger. Das kann es
                    dir erschweren, dich für einen Kredit zu qualifizieren. Entscheidend ist, dass das Nettoeinkommen
                    nach den Abschreibungen dem von den Kreditgebern bevorzugten Verschuldungsgrad entspricht. Also ist
                    es für Selbstständige wichtig, die Schulden niedrig zu halten. Dokumentiere deine Steuerabzüge gut,
                    halte das Nettoeinkommen möglichst hoch, deine Schuldenlast möglichst niedrig und führe am besten
                    getrennte Geschäfts- und Privatkonten.
                </p>
                <hr />

                <h2>Als Selbstständige:r zahlst du möglicherweise mehr für deine Hypothek</h2>
                <p>
                    Da einige Kreditgeber selbständige Antragsteller als risikoreichere Kreditnehmer betrachten, musst
                    du möglicherweise mehr für deine Hypothek bezahlen. Aber lass dich davon nicht abschrecken, denn
                    durch eine gute Finanzberatung lassen sich viele Möglichkeiten finden, einen niedrigen{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinssatz
                    </Link>{" "}
                    für dich zu verhandeln. Auch eine spätere Umschuldung ist möglich, wenn du bewiesen hast, dass du
                    regelmäßig und verlässlich zahlen kannst. Außerdem kann eine höhere Anzahlung dir bessere
                    Konditionen verschaffen. Bei der Wahl des richtigen{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredits
                    </Link>{" "}
                    solltest du also auf keinen Fall zu schnell sein und alle deine Optionen gut überlegen.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt zum Kreditvergleich!
                    </a>
                </p>
                <hr />

                <h2>Wie du deine Chancen auf einen Kredit verbesserst</h2>
                <p>
                    Als Selbstständige:r kannst du mehrere Maßnahmen ergreifen, um deine Chance auf einen
                    Immobilienkredit zu erhöhen:
                </p>
                <ul>
                    <li>Lasse dein Unternehmen registrieren und lizenzieren</li>
                    <li>Zahle dir selbst ein Gehalt und nicht nur einen Eigenanteil</li>
                    <li>Senke deine Schuldenlast</li>
                    <li>Reduziere deine Steuerabzüge</li>
                    <li>Trenne Geschäfts- und Privatkonten voneinander</li>
                    <li>
                        Führe immer regelmäßige und detaillierte Aufzeichnungen (Hierfür gibt es auch Online-Tools und
                        Apps)
                    </li>
                    <li>Ziehe eine größere Anzahlung in Betracht</li>
                    <li>Ziehe die Zusammenarbeit mit einem anderen Kleinunternehmen in Betracht</li>
                </ul>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt zum Erstgespräch!
                    </a>
                </p>
                <hr />

                <h2>Es ist nicht unmöglich!</h2>
                <p>
                    Als Selbstständige:r kann es schwierig sein, einen Kredit bewilligt zu bekommen. Doch wenn du gut
                    informiert und vorbereitet bist, kann dich nichts davon abhalten. Außerdem ist der österreichische
                    Staat bereit, die Wirtschaft nach den vielen Lockdowns zu fördern und bietet Unterstützung an. Vor
                    allem eine gute Beratung kann den Weg zum Kredit deutlich vereinfachen und dir ermöglichen, den
                    Kredit zu bekommen, den du verdienst.
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"kreditSelbststaendige"}></BreadcrumbList>
            <ArticleStructuredData
                page={"kreditSelbststaendige"}
                heading={"Du bist Selbstständig? Das wichtigste rund um den Kredit für Selbständige"}
            />
        </Layout>
    );
};

export default KreditSelbststaendige;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.kredit-selbststaendige", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
